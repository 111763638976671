import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { ThemeModule } from 'app/@modules/theme.module';

import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'

import { reducers, metaReducers } from 'app/@store/reducers'
import { UserEffects } from 'app/@store/user/effects'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { jwtAuthService } from '@services/jwt';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ConfigService } from '@services/api/config.service';
import { APP_INITIALIZER } from '@angular/core';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { AppuserserviceComponent } from './@components/UseraccountManagement/appuserservice/appuserservice.component';
import { AppuserservicexpectationComponent } from './@components/UseraccountManagement/appuserservicexpectation/appuserservicexpectation.component';
import { PermissionComponent } from './@components/permission/permission.component';
import { AccusermangementComponent } from './@components/UseraccountManagement/accusermangement/accusermangement.component';

// import { ServiceExceptionComponent } from './@components/service-exception/service-exception.component';
// import { UserservicelistComponent } from './@components/userservicelist/userservicelist.component';
// import { UsermemeberlistComponent } from './@components/usermemeberlist/usermemeberlist.component';




@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    ThemeModule,
    AppRoutingModule,
    NzPageHeaderModule,
    NzPaginationModule,


    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

  ],
  providers: [
    jwtAuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [ConfigService],
      multi: true,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInitializerFactory(configsLoaderService: ConfigService) {
  return () => configsLoaderService.loadConfigs();
}