import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdduserlistComponent } from '@components/adduserlist/adduserlist.component';
import { PermissionComponent } from '@components/permission/permission.component';
import { AccusermangementComponent } from '@components/UseraccountManagement/accusermangement/accusermangement.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    redirectTo:'permission',
    pathMatch:'full'
  },

  ///permission?id=6&product=6070
    {
    path: '',
    loadChildren: () => import('app/@modules/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('app/@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'permission',
    loadChildren: () => import('app/@modules/usergroup/usergroup.module').then(m => m.UsergroupModule)
  },
  // {
  //   path: 'UsergroupModule',
  //   loadChildren: () => import('app/@modules/usergroup/usergroup.module').then(m => m.UsergroupModule)
  // },
  // {
  //   path: 'permmission',
  //   component:PermissionComponent
  // },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
