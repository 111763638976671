import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzModulesModule } from 'app/@modules/nz-modules.module'

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { FormsModule           } from '@angular/forms'                  ;
import { ReactiveFormsModule   } from '@angular/forms'                  ;
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators' ;

@NgModule({
  declarations: [],
  imports: [
    CommonModule ,
    PerfectScrollbarModule,
    NzModulesModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
  ],
  exports :[
    NzModulesModule,
    PerfectScrollbarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
  ]
})
export class ThemeModule { }
