import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface Configs {
  apiUrl  : string,
  testUrl : string
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configs: Configs;

  constructor( private http: HttpClient ) { }

  get config() {
    return this.configs;
  }

  get apiUrl() {
    return this.configs.apiUrl;
  }

  get testUrl() {
    return this.configs.testUrl;
  }

  public async loadConfigs(): Promise<any> {
    return this.http.get('./assets/appconfig.json').pipe(settings => settings)
      .toPromise()
      .then(settings => {
        this.configs = settings as Configs;
      });
  }

}

